<template>
  <v-container v-if="!isAuthenticated" fill-height class="d-flex">
    <v-card v-if="!isAuthenticated" class="mx-auto" width="500"><login-dialog /></v-card>
  </v-container>
  <v-container fluid v-else>
    <div class="pa-4">
      <h2 class="mb-2 text-h2">CUBIC Galaxy</h2>
      <p>Welcome to the CUBIC Galaxy administration site.</p>
      <p v-if="isAdmin">You have administrative access to the system.</p>
      <p v-else-if="isSiteManager">You have site manager access to the system and can administer companies and users, and assign licenses to companies.</p>
      <p v-else-if="isManager">You have manager access and can administer users with access to CUBIC Galaxy in your organization.</p>
      <p v-else>You have user access to the system which lets you use CUBIC Galaxy.</p>

      <latest-installer-download class="mt-8" />
    </div>
  </v-container>
</template>

<script>
import LoginDialog from '@/components/LoginDialog.vue';
import { mapGetters } from 'vuex';
import LatestInstallerDownload from '@/components/LatestInstallerDownload.vue';

export default {
  name: 'Home',
  components: { LoginDialog, LatestInstallerDownload },
  computed: {
    ...mapGetters(['isAuthenticated', 'isManager', 'isSiteManager', 'isAdmin']),
  },
};
</script>
