<template>
  <div>
    <h5 class="text-h5">Download latest installer</h5>
    <p>The latest version of the CUBIC Galaxy installer is <strong>{{ installerVersion }}</strong></p>
    <v-btn depressed @click="downloadInstaller">
      <v-icon left>mdi-download</v-icon>
      Download installer {{ installerVersion }}
    </v-btn>
  </div>
</template>
<script>
import apiService from '@/services/apiService';

export default {
  name: 'latest-installer-download',
  data: () => ({
    installer: null,
  }),
  computed: {
    installerVersion() {
      return this.installer?.version ?? 'N/A';
    }
  },
  methods: {
    async loadInstaller() {
      this.installer = await apiService.getLatestInstallerInfo();
    },
    async downloadInstaller() {
      const element = this.installer.elements[0];
      const fileContent = await apiService.getElementFile(element.id);

      this.$util.downloadAsFile(fileContent, element.filename);
    },
  },
  created() {
    this.loadInstaller();
  }
}
</script>